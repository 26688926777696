import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { useHomePageData } from "@staticQueries";
import { PreFooter, SEO, VideosPopout } from "@common";
import { Section } from "@ui";
import HomeIntro from "./HomeIntro";
import HomeCurriculum from "./HomeCurriculum";
import HomeTestimonial from "./HomeTestimonial";
import HomeBlogNews from "./HomeBlogNews";
import HomeResearch from "./HomeResearch";

const HomePage = () => {
  const { meta, prefooter } = useHomePageData();

  const [{ breakpoints }, dispatch] = useAppState();
  const { lg } = breakpoints;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: lg ? "large" : "small",
          color: "blue",
        },
      },
    });
  }, [lg]);

  return (
    <>
      <SEO {...meta} />
      <Section className="bg-blue lg:rounded-b-lg overflow-hidden">
        <HomeIntro />
      </Section>

      <Section>
        <HomeCurriculum />
      </Section>

      <Section size="sm" className="bg-gray-1 sm:rounded-lg my-6">
        <VideosPopout />
      </Section>

      <Section hasPaddingTop={false}>
        <HomeTestimonial />
      </Section>

      <Section hasPaddingTop={false}>
        <HomeBlogNews type="news" />
      </Section>

      <Section className="bg-blue lg:rounded-lg overflow-hidden">
        <HomeResearch />
      </Section>

      <Section>
        <HomeBlogNews type="blog" />
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default HomePage;
