import React from "react";
import PropTypes from "prop-types";
import { SingleCard } from "@modules/singles/index";
import { Text, RichText, Button, HeadingAccent, AppLink, Container } from "@ui";
import { useHomeNewsData, useHomeBlogData } from "@staticQueries";

const HomeBlogNews = ({ type }) => {
  const { heading, description, ctaText, ctaLink, posts = [] } =
    type === "news" ? useHomeNewsData() : useHomeBlogData();

  return (
    <Container size="lg" className="flex flex-col items-center">
      <div className="flex flex-col items-center text-center max-w-sm">
        <HeadingAccent />
        <Text preset="h2" className="text-blue">
          {heading}
        </Text>
        {description && description.html && (
          <RichText html={description.html} className="mt-6" />
        )}
      </div>

      <div className="flex flex-wrap w-full -mx-3 my-8">
        {posts.map(({ uid, title, date, image, categories }) => (
          <SingleCard
            key={uid}
            type={type}
            slug={uid}
            title={title}
            date={date}
            image={image}
            hideImage={type === "blog"}
            categories={categories}
            ctaText={type === "news" ? "read the article" : "read the post"}
            forceSmall
            className="w-full sm:w-1/2 md:w-1/3"
          />
        ))}
      </div>

      <AppLink to={ctaLink.url}>
        <Button preset="blue" text={ctaText} />
      </AppLink>
    </Container>
  );
};

HomeBlogNews.propTypes = {
  type: PropTypes.oneOf(["news", "blog"]).isRequired,
};

export default HomeBlogNews;
