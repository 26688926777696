import React from "react";
import {
  Text,
  RichText,
  Button,
  HeadingAccent,
  AppLink,
  RandomShapes,
  Container,
} from "@ui";
import HomeResearchVisualization from "./HomeResearchVisualization";
import { useHomeResearchData } from "@staticQueries";

const HomeResearch = () => {
  const {
    heading,
    description,
    ctaText,
    ctaLink,
    corQuestionsHeading,
    corQuestions,
    visualizationImages,
  } = useHomeResearchData();

  const skews = ["-10deg", "-5deg", "-3deg"];

  return (
    <>
      <RandomShapes />
      <Container size="lg" className="relative z-10">
        <HeadingAccent />
        <Text preset="h2" className="mb-6 md:mb-0 text-white">
          {heading}
        </Text>

        <div className="md:flex md:flex-row-reverse items-center">
          <div className="relative mt-32 mb-16 md:my-0 md:w-1/2 md:pl-6">
            <HomeResearchVisualization images={visualizationImages} />
          </div>

          <div className="flex flex-col justify-center md:w-1/2 md:pr-6 text-white">
            <RichText html={description.html} className="mb-6" />

            <div className="bg-blue-1-20 px-4 py-6 rounded-lg mb-6">
              <Text preset="h4">{corQuestionsHeading}</Text>

              <ol className="list-reset font-serif text-gray italic mt-3">
                {corQuestions.map(({ id, title, color }, idx) => (
                  <li key={id} className="mt-1">
                    <div className="inline-block p-1 pl-4 relative">
                      <div
                        className="absolute inset-0 z-10"
                        style={{
                          backgroundColor: color,
                          transform: `skew(${skews[idx]})`,
                        }}
                      />
                      <Text preset="p" tag="span" className="relative z-20">
                        {`${idx + 1}. ${title}`}
                      </Text>
                    </div>
                  </li>
                ))}
              </ol>
            </div>

            <div className="text-center md:text-left">
              <AppLink
                to={ctaLink.url}
                target={ctaLink.target}
                className="inline-block"
              >
                <Button text={ctaText} preset="white" />
              </AppLink>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HomeResearch;
