import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { CurriculumCard } from "@modules/curriculum/index";
import {
  Text,
  RichText,
  Button,
  Image,
  HeadingAccent,
  Carousel,
  AppLink,
  Container,
} from "@ui";
import { useHomeCurriculumData } from "@staticQueries";

const HomeCurriculum = () => {
  const {
    heading,
    description,
    ctaText,
    ctaLink,
    image,
    featuredCurriculum,
  } = useHomeCurriculumData();

  const [{ breakpoints }, dispatch] = useAppState();
  const { sm } = breakpoints;

  useEffect(() => {
    dispatch({ type: "deSelectCard" });
  }, []);

  const getSlides = isCarousel =>
    featuredCurriculum.map(
      ({ id, uid, title, type, illustration, tags, color }) => (
        <CurriculumCard
          key={id}
          uid={uid}
          title={title}
          type={type}
          illustration={illustration}
          tags={tags}
          color={color}
          disableHoverStyle={isCarousel}
          className={`w-full sm:w-1/2 md:w-1/3 ${
            isCarousel ? "h-full" : "h-auto"
          }`}
        />
      )
    );

  return (
    <>
      <Container size="lg" className="md:flex md:flex-row-reverse">
        <div className="max-w-xs mx-auto mb-8 md:w-1/2 md:pl-16 md:max-w-none md:mb-0">
          <div
            className="relative w-full rounded-lg overflow-hidden"
            style={{ paddingBottom: "100%" }}
          >
            <Image image={image} />
          </div>
        </div>

        <div className="flex flex-col justify-center md:w-1/2">
          <HeadingAccent />
          <Text preset="h2" className="text-blue mb-6">
            {heading}
          </Text>
          <RichText html={description.html} className="mb-6" />
          <div className="text-center md:text-left">
            <AppLink to={ctaLink.url} className="inline-block">
              <Button preset="blue" text={ctaText} />
            </AppLink>
          </div>
        </div>
      </Container>

      {sm ? (
        <Container className="mt-8 md:mt-16">
          <div className="flex flex-wrap -mx-3">{getSlides()}</div>
        </Container>
      ) : (
        <Carousel
          infinite={false}
          stagePadding={28}
          overflowVisible
          showDots={false}
          showButtons={false}
          className="mt-8 md:mt-16"
        >
          {getSlides(true)}
        </Carousel>
      )}
    </>
  );
};

export default HomeCurriculum;
