import React, { useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import AnimationBackground from "@svg/homepage-animation-bg.svg";
import {
  Text,
  RichText,
  Button,
  Image,
  SVGAnimation,
  AppLink,
  Container,
  Icon,
} from "@ui";
import { useHomeIntroData } from "@staticQueries";

const HomeIntro = () => {
  const [, dispatch] = useAppState();
  const [animationLoaded, setAnimationLoded] = useState(false);

  const {
    heading,
    description,
    ctaText,
    ctaLink,
    publicationsLabel,
    logos,
    videoCtaText,
    video,
    animation,
  } = useHomeIntroData();

  const openModal = () => {
    dispatch({
      type: "openModal",
      name: "videoEmbed",
      context: {
        title: video.title,
        youtubeVideoId: video.youtubeVideoId,
      },
    });
  };

  return (
    <Container size="lg" className="pt-12">
      <div className="md:flex">
        <div className="md:flex-1 md:pr-8 md:py-12 text-white">
          <Text preset="h1">{heading}</Text>

          <RichText html={description.html} className="text-lg mt-6" />

          <div className="text-center md:text-left">
            <AppLink
              to={ctaLink.url}
              target={ctaLink.target}
              className="inline-block mt-8"
            >
              <Button text={ctaText} preset="white" />
            </AppLink>
          </div>

          <div className="bg-blue-4-50 px-8 pt-6 pb-8 rounded-lg mt-8">
            <Text preset="h4" className="text-center mb-4">
              {publicationsLabel}
            </Text>
            <div className="flex flex-wrap md:flex-no-wrap items-center justify-between -mx-4">
              {logos.map(image => (
                <div
                  key={image.id}
                  className="w-1/2 lg:w-auto p-4 lg:py-0 text-center"
                >
                  <span
                    className="relative inline-block"
                    style={{ height: "32px" }}
                  >
                    <Image
                      image={image}
                      objectFit="contain"
                      forceHeight={32}
                      showLQIP={false}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div
          className="relative md:w-1/2 mt-12 mb-6 md:my-0 p-4 md:p-12 transition"
          style={{ opacity: animationLoaded ? 1 : 0 }}
        >
          <div className="absolute z-10 inset-0">
            <AnimationBackground />
          </div>

          <SVGAnimation
            id="home-intro-animation"
            className="relative z-20 h-full"
            json={animation}
            loopAt={41}
            onLoaded={() => setAnimationLoded(true)}
          />

          {video && videoCtaText && (
            <div className="absolute left-1/2 top-1/2 translate-center z-20">
              <button
                type="button"
                onClick={openModal}
                className={classNames({
                  "outline-none focus:outline-none text-white hover:text-blue transition-home-animation-cta": true,
                  "opacity-0 scale-50": !animationLoaded,
                  "opacity-100": animationLoaded,
                })}
              >
                <Icon
                  name="videoPlayLg"
                  className="w-16 h-16 text-white hover:scale-110 transition-fast"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default HomeIntro;
