import React from "react";
import { Text, RichText, Image, RandomShapes, Container } from "@ui";
import { useHomeTestimonialData } from "@staticQueries";
import { getTailwindUtility } from "@utils";

const HomePartners = () => {
  const { text, person, photo, role, image } = useHomeTestimonialData();
  const colors = getTailwindUtility("colors");
  const filter = colors.blue["3"].replace("#", "");

  return (
    <div className="relative">
      <div className="absolute z-10 top-0 left-0 right-0 h-1/2 bg-blue rounded-lg">
        <RandomShapes seed={300} />
      </div>

      <Container size="lg" className="relative pt-8 md:pt-16 lg:pt-24">
        <div className="relative z-20 border-1/2rem border-white bg-white rounded-lg">
          <div className="py-4 px-4 md:pt-8 lg:px-16">
            <RichText
              html={text.html}
              className="RichText--quote font-serif italic font-bold text-xl md:text-2xl mb-4"
            />

            <div className="flex items-center">
              <div className="relative w-12 h-12 rounded-full border-4 border-blue-1 overflow-hidden">
                <Image image={photo} />
              </div>
              <div className="flex-1 mx-3">
                <Text preset="h3">{person}</Text>
                <Text preset="label" className="font-bold text-xs text-gray-5">
                  {role}
                </Text>
              </div>
            </div>
          </div>

          <div className="relative rounded-lg overflow-hidden mt-4">
            <Image
              image={{ ...image, filter }}
              objectFit="contain"
              className="block z-10"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HomePartners;
