import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useAppState } from "@state/state";
import { Image, imageProps } from "@ui";

const hasIntersectionObserver =
  typeof window !== "undefined" && !!window.IntersectionObserver;

const HomeResearchVisualization = ({ images }) => {
  const [inViewport, setInViewport] = useState(!hasIntersectionObserver);
  const el = useRef();
  const [{ layout }] = useAppState();

  useEffect(() => {
    if (el.current && hasIntersectionObserver) {
      // reset inViewport to false after React hydration
      setInViewport(false);

      const observer = new IntersectionObserver(
        (entries, instance) => {
          if (entries[0].intersectionRatio > 0) {
            setInViewport(true);
            instance.unobserve(el.current);
          }
        },
        { threshold: 0.5 }
      );

      observer.observe(el.current);
    }
  }, [layout.rendered]);

  const { image: thoughtBubble } = images.find(
    image => image.type === "Thought Bubble"
  );
  const { image: foreground } = images.find(
    image => image.type === "Foreground"
  );
  const { image: background } = images.find(
    image => image.type === "Background"
  );

  if (!layout.rendered) {
    return null;
  }

  return (
    <div ref={el} className="HomeResearchVisualization relative">
      <div
        className={classNames({
          "HomeResearchVisualization__thought-bubble absolute z-30": true,
          "HomeResearchVisualization__thought-bubble--in-viewport": inViewport,
        })}
      >
        <Image image={thoughtBubble} objectFit="contain" showLQIP={false} />
      </div>

      <div
        className={classNames({
          "HomeResearchVisualization__foreground absolute w-full bottom-0 left-0 z-20": true,
          "HomeResearchVisualization__foreground--in-viewport": inViewport,
        })}
      >
        <Image image={foreground} objectFit="contain" showLQIP={false} />
      </div>

      <div
        className={classNames({
          "HomeResearchVisualization__background absolute w-full bottom-0 left-0 z-10": true,
          "HomeResearchVisualization__background--in-viewport": inViewport,
        })}
      >
        <Image image={background} objectFit="contain" showLQIP={false} />
      </div>
    </div>
  );
};

HomeResearchVisualization.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: imageProps.props.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default HomeResearchVisualization;
